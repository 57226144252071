<template>
  <div class="progress-modal-backdrop">
    <div
        class="progress-modal"
        role="dialog"
        aria-labelledby="progress-modalTitle"
        aria-describedby="progress-modalDescription"
    >
      <img
          v-if="hasActiveSubscription"
          src="../assets/close-modal.png"
          @click="close()"
          aria-label="Close modal"
          class="btn-progress-close"
          alt="close-modal"
      />
      <header
          class="progress-modal-header"
          id="progress-modalTitle"
      >
        <slot name="header">
          <h1>Bestellen</h1>
        </slot>
      </header>

      <div class="progress-modal-body-wrapper">
        <section
            class="progress-modal-body"
            id="progress-modalDescription"
        >
          <div class="s-container">
            <div class="s-panel-content">
              <div>
                <template v-if="hasActiveSubscription">
                  <p>U heeft een geldig abonnement.</p>
                </template>
                <template v-else>
                  <template v-if="currentSubscriptionCanBeRenewed">
                    <p>Helaas, uw demo abonnement is verlopen.</p>
                    <p>U kunt verder met Spell-it door nu direct te verlengen.</p>
                  </template>
                  <template v-else>
                    <p>U kunt niet meer inloggen omdat uw abonnement verlopen is.</p>
                    <p>Wilt u toch verder met Spell-it? Neem dan contact met ons op.</p>
                  </template>
                </template>
                <br>
                <br>
                <v-btn
                    class="square-button green"
                    height="50"
                    color="#fff"
                    tile
                    small
                    rounded
                    @click.stop="subscribe()"
                >
                  {{ subscribeTitle }}
                </v-btn>
              </div>
            </div>
            <div class="s-panel-image">
              <img
                  width="300px" height="300px" class="avatar"
                  :src="buddyUrl"
              />
            </div>
          </div>

        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
  name: "c-subscription",
  data: () => ({
    subscribeTitle: 'Ga naar bestelpagina',
    subscribeSubmitting: false,
  }),
  computed: {
    ...mapGetters({
      profile: "user/getProfile",
      buddyId: "user/getBuddyId",
    }),
    buddyUrl() {
      return this.hasActiveSubscription ? `https://cdn.spellit.nl/buddies/${this.buddyId}/large.png` : `https://cdn.spellit.nl/buddies/${this.buddyId}/sad.png`
    },
    url() {
      return `https://cdn.spellit.nl/buddies/${this.buddyId}/sad.png`
    },
    hasActiveSubscription() {
      return this.profile.has_active_subscription
    },
    currentSubscriptionCanBeRenewed() {
      return this.profile.current_subscription ? this.profile.current_subscription.can_be_renewed : false
    },
    profileId() {
      return this.profile.current_subscription.id
    },
  },
  methods: {
    subscribe() {
      if (this.subscribeSubmitting) return;
      this.subscribeSubmitting = true;
      this.subscribeTitle = 'Bezig met laden...';
      Vue.prototype.$api.get(`/api/v1/subscription/renewal-token/${this.profileId}`)
          .then(({data}) => {
            window.location = data.url
            setTimeout(() => {
              this.subscribeSubmitting = false;
              this.subscribeTitle = 'Ga naar bestelpagina';
            }, 3000)
          })
          .catch(() => {
            this.subscribeTitle = 'Bestellen niet mogelijk';
          })
    },
    close() {
      this.$router.push({name: "world_overview"})
    },
  },
  mounted() {
    if (this.hasActiveSubscription) {

    }
  },
}
</script>

<style scoped>
h3 {
  color: #016D47;
}

.s-container {
  margin-top: 0;
  display: flex;
  width: 100%;
}

.s-panel-content {
  text-align: left;
  width: 50%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-panel-image {
  width: 50%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.progress-modal {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  background: #F8F4E0;
  border-radius: 25px;
  outline: 4px solid #006c49;
  width: 80vw;
  height: 70vh;
  position: relative;
}

h1 {
  margin: -40px 0 -20px 60px;
  padding: 10px 5px 10px 5px;
  width: 50%;
  transform: rotate(-2deg);
  text-align: center;
  background: #FFD900;
  background: url('../assets/titelbalk.png') no-repeat;
  background-size: cover;
  color: #016D47;
  z-index: 9999;
  image-rendering: auto;
}

.progress-modal-header,
.progress-modal-footer {
  display: flex;
  z-index: 999;
}

.progress-modal-header {
  position: absolute;
  width: 600px;
  color: #4AAE9B;
  justify-content: space-between;
}

.progress-modal-body {
  z-index: 999;
  background: #F8F4E0;
  height: 100%;
  overflow: auto;
  display: flex;
}

.progress-modal-body-wrapper {
  overflow: hidden;
  position: relative;
  padding: 50px 60px;
  height: 100%;
}

.btn-progress-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 62px;
  border: 62px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
  z-index: 999;
}
</style>