import Vue from "vue";
import router from "@/router";
import Exercise from "@/models/exercises/Exercise";
import ExerciseStudent from "@/models/exercises/ExerciseStudent";
import Test from "@/models/exercises/Test";

const state = {
  exercise: {},
  currentInput: null,
  lastAnswer: null,
  hintVisible: false,
  wordVisible: false,
  repeatables: [],
  test: null,
  progress: {
    current: null,
    total: null,
    step: 0,
    word: null,
    errors: 0,
    cheats_used: false,
    hints_used: false,
  },
  story: {
    chosenWords: [],
  },
};

const mutations = {
  SET_EXERCISE(state, payload) {
    const exerciseValue =
      payload && !payload.isEmpty() ? new Exercise(payload) : {};
    Vue.set(state, "exercise", exerciseValue);
  },
  SET_TEST(state, payload) {
    const testValue = payload && !payload.isEmpty() ? new Test(payload) : {};
    Vue.set(state, "test", testValue);
  },
  SET_CURRENT_INPUT(state, payload) {
    state.currentInput = payload;
  },
  SET_HINT_VISIBILITY(state, payload) {
    state.hintVisible = payload;
  },
  SET_REPEATABLES(state, payload) {
    if (typeof payload === "object") {
      payload = Object.values(payload);
    }
    Vue.set(state, "repeatables", payload);
  },
  SET_STEP_PROGRESS(state, payload) {
    state.progress.step = payload;
  },
  SET_WORD_PROGRESS(state, payload) {
    state.progress.word = payload;
  },
  SET_ERROR_PROGRESS(state, payload) {
    state.progress.errors = payload;
  },
  SET_CHEAT_USED(state, payload) {
    state.progress.cheats_used = payload;
  },
  SET_HINT_USED(state, payload) {
    state.progress.hints_used = payload;
  },
  SET_WORD_VISIBILITY(state, payload) {
    state.wordVisible = payload;
  },
  SET_TWO_WORD_STORY_WORDS(state, payload) {
    state.story.chosenWords = payload;
  },
};

const actions = {
  fetchData({ rootGetters, dispatch }, routeId = null) {
    routeId = routeId ?? rootGetters["router/getRouteID"];
    if (!routeId) return false;
    if (rootGetters["router/isTest"]) {
      dispatch("fetchTest", routeId);
    }

    if (rootGetters["router/isExercise"]) {
      dispatch("fetchExercise", routeId);
    }
  },
  fetchTest({ commit }, testId) {
    return Vue.prototype.$api.endpoints.test.get(testId).then((data) => {
      commit("SET_TEST", data);
      commit("SET_STEP_PROGRESS", 1);
    });
  },
  fetchExercise({ commit, getters }, exerciseId) {
    return Vue.prototype.$api.endpoints.exercise
      .get(exerciseId)
      .then((data) => {
        commit("SET_EXERCISE", data);
        if (getters.getCurrentStudentExercise?.is_complete) {
          commit("SET_STEP_PROGRESS", 3);
        } else {
          commit("SET_STEP_PROGRESS", 1);
        }
      });
  },
  fetchRepeatables({ commit }) {
    return Vue.prototype.$api.endpoints.repeatable
      .get("", false)
      .then((data) => {
        commit("SET_REPEATABLES", data);
      });
  },
  postData({ dispatch, rootGetters }, payload) {
    if (rootGetters["router/isTest"]) {
      return dispatch("postTest");
    }
    if (rootGetters["router/isExercise"]) {
      return dispatch("postExercise", {
        currentInput: payload?.currentInput ?? null,
        repeatWord: payload?.repeatWord ?? true,
      });
    }
  },
  postTest({ getters, commit, dispatch, rootGetters }) {
    if (!rootGetters["router/isTest"]) return false;

    this.num_errors = 0;

    [...getters.getWord].forEach((letter, index) => {
      if (letter !== getters.getCurrentInput?.word[index])
        commit("SET_ERROR_PROGRESS", getters.getErrors + 1);
    });

    Vue.prototype.$api.endpoints.test
      .put(rootGetters["router/getRouteID"], {
        step_id: getters.getTestStep,
        word: getters.getWord,
        num_errors: getters.getErrors,
      })
      .then((test) => {
        commit("SET_TEST", test);
        if (test.is_complete) {
          dispatch("nextStep");
        } else {
          dispatch("nextWord");
        }
      });
  },
  postExercise({ commit, dispatch, rootGetters, getters }, payload) {
    let id = router.currentRoute.params.exercise_id;
    const oldCurrentWord = getters.getCurrentStudentExercise.current_word;

    const currentInputData =
      payload.currentInput ??
      (typeof getters.getCurrentInput === "string"
        ? null
        : getters.getCurrentInput);

    const data = {
      exercise_id: id,
      hints: 0,
      startTime: rootGetters["timer/getStartTime"],
      time: rootGetters["timer/getSeconds"],
      difficulty: rootGetters["lessonPage/getDifficulty"](),
      word: getters.getWord,
      cheats_used: getters.isCheatUsed ? 1 : 0,
      hints_used: getters.isHintUsed ? 1 : 0,
      data: currentInputData,
    };

    return Vue.prototype.$api.endpoints.exercise.put(id, data).then((data) => {
      commit("SET_EXERCISE", data);
      const currentStudentExercise = getters.getCurrentStudentExercise;
      const newCurrentWord = currentStudentExercise.current_word;
      if (currentStudentExercise.is_complete) {
        dispatch("nextStep");
      } else {
        if (oldCurrentWord !== newCurrentWord) {
          dispatch("nextWord");
        } else {
          if (currentStudentExercise?.is_retry) {
            commit("SET_CURRENT_INPUT", "");
          }
          if (payload.repeatWord) {
            dispatch("repeatWord");
          }
        }
      }
    });
  },
  resetExercise({ rootGetters, commit }) {
    return Vue.prototype.$api.endpoints.reset
      .put(rootGetters["router/getRouteID"], {
        difficulty: rootGetters["lessonPage/getDifficulty"](),
        shuffle: 1,
      })
      .then((data) => {
        commit("timer/SET_SECONDS", 0, { root: true });
        commit("SET_EXERCISE", data);
        commit("SET_STEP_PROGRESS", 1);
      });
  },
  previousStep({ commit, state }) {
    commit("SET_STEP_PROGRESS", state.progress.step - 1);
  },
  nextStep({ commit, state, dispatch, rootGetters }) {
    const maxSteps = rootGetters["template/isTemplate"]("StapDictee") ? 4 : 3;
    if (maxSteps > state.progress.step) {
      commit("SET_STEP_PROGRESS", state.progress.step + 1);

      if (state.progress.step === 2) {
        dispatch("timer/startTimer", null, { root: true });
        dispatch("playWord");
      }

      if (state.progress.step === 3) {
        dispatch("timer/killTimer", null, { root: true });
        dispatch("audio/clearAudio", null, { root: true });
      }
    }
  },
  async playWord({ rootGetters, rootState, getters }) {
    const templateParameters =
      rootGetters["template/getCurrentTemplateParameters"];

    const word = getters.getWord;
    for (const playing of templateParameters.autoplay) {
      await rootState.audio.player[playing](word, 500, true);
      // await rootState.audio.player[playing](getters.getWord, 500, true);
    }
  },
  repeatWord({ getters, rootState, dispatch }) {
    dispatch("incrementErrors");
    if (getters.getWord) {
      rootState.audio.player.word(getters.getWord, 500, true);
    }
  },
  nextWord({ commit, state, dispatch }) {
    commit("SET_WORD_PROGRESS", state.progress.word + 1);
    commit("SET_CURRENT_INPUT", "");
    commit("SET_ERROR_PROGRESS", 0);
    commit("SET_CHEAT_USED", false);
    commit("SET_HINT_USED", false);
    commit("SET_HINT_VISIBILITY", false);
    dispatch("playWord");
  },
  incrementErrors({ commit, getters }) {
    return commit("SET_ERROR_PROGRESS", getters.getErrors + 1);
  },
  continueExercise({ getters, dispatch }) {
    if (getters.getProgress.step === 1) {
      return dispatch("nextStep");
    } else if (getters.getProgress.step === 2) {
      return dispatch("postData");
    }
    // if (getters.getProgress.step === 3) {
    //   dispatch("nextWord");
    // }
  },
  clearProgress({ commit, dispatch }) {
    commit("SET_STEP_PROGRESS", 0);
    commit("SET_ERROR_PROGRESS", 0);
    commit("SET_CHEAT_USED", false);
    commit("SET_HINT_USED", false);
    commit("SET_CURRENT_INPUT", "");
    commit("SET_HINT_VISIBILITY", false);
    commit("SET_EXERCISE", {});
    commit("SET_TEST", {});
    commit("timer/SET_SECONDS", null, { root: true });
    commit("timer/SET_START_TIME", null, { root: true });
    dispatch("timer/killTimer", null, { root: true });
  },
  useCheat({ commit }) {
    commit("SET_CHEAT_USED", true);
  },
  useHint({ commit }) {
    commit("SET_HINT_USED", true);
  },
  checkRestrictedInput({ getters, dispatch }, { event, input }) {
    event.preventDefault();

    const answer = getters.getAnswer;
    console.log('answer', answer);

    // if (event.key === 'Enter') {
    //   console.log(1)
    //   console.log(input.length)
    //   console.log(answer.length)
    //   if (input.length < answer.length) {
    //     return;
    //     //prevent fault
    //     const isAllowed = true;
    //     return { isAllowed, input };
    //   }
    // }

    if (/[ a-zA-Z'À-ž\u0370-\u04FF-]/.test(event.key)) {
      const tempInput = (input ?? "") + event.key;
      const correctWordLetter = answer[tempInput.length - 1]
        ? answer[tempInput.length - 1].toLowerCase()
        : null;

      const inputLetter = event.key.toLowerCase();

      const isAllowed =
        correctWordLetter === inputLetter || tempInput.length > answer.length;

      if (
        tempInput.length <= answer.length &&
        correctWordLetter === inputLetter
      ) {
        input = tempInput;
      } else if (tempInput.length <= answer.length) {
        dispatch("postData", { currentInput: { word: tempInput } });
      }

      return { isAllowed, input };
    }

    console.log('asasas');
    return;
  },
  wrongInput(obj, payload) {
    payload.classList.add("wrong-input");
    setTimeout(() => payload.classList.remove("wrong-input"), 300);
  },
  pickStoryWord({ getters, commit, rootGetters }, word) {
    const newArray = getters.getTwoWordStoryWords;
    const wordIndex = newArray.indexOf(word);
    if (wordIndex === -1) {
      rootGetters["audio/getAudioPlayer"].word(word, 0, true);
      if (newArray.length > 1) {
        newArray.splice(-1, 1, word);
      } else {
        newArray.push(word);
      }
    } else {
      newArray.splice(wordIndex, 1);
    }

    commit("SET_TWO_WORD_STORY_WORDS", newArray);
  },
  resetStoryWords({ commit }) {
    commit("SET_TWO_WORD_STORY_WORDS", []);
  },
};

const getters = {
  getExercise: (state) => {
    if (state.exercise instanceof Exercise) {
      return state.exercise;
    }

    return null;
  },
  getExerciseData(state, getters) {
    return getters.getCurrentStudentExercise?.exercise_data;
  },
  getWordStructure(state, getters) {
    return (
      getters.getCurrentStudentExercise?.exercise_data?.word?.structure.split(
        ","
      ) ?? ""
    );
  },
  getTest(state) {
    if (state.test instanceof Test) {
      return state.test;
    }

    return null;
  },
  /**
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @return {ExerciseStudent | null}
   */
  getCurrentStudentExercise(state, getters, rootState, rootGetters) {
    let studentExercise = null;
    let difficulty = rootGetters["lessonPage/getDifficulty"]();

    if (state.exercise.student_exercise) {
      studentExercise = Object.values(state.exercise.student_exercise).find(
        (e) => {
          return e.difficulty === difficulty;
        }
      );
    }

    if (studentExercise instanceof ExerciseStudent) {
      return studentExercise;
    }

    return null;
  },
  getExpectedInput(state, getters) {
    return getters.getCurrentStudentExercise?.expected_input;
  },
  getCurrentInput(state) {
    return state.currentInput;
  },
  getAnchor(state, getters) {
    return getters.getCurrentStudentExercise?.exercise_data?.anchor;
  },
  getAnchors(state, getters) {
    return getters.getCurrentStudentExercise?.exercise_data?.anchors;
  },
  getAnchorImage(state, getters) {
    const anchor = getters.getAnchor;
    if (anchor && anchor.image) {
      return `https://cdn.spellit.nl/anchor/${anchor.image}.png`;
    }
    return null;
  },
  getAnchorHint(state, getters) {
    return getters.getAnchor?.hint;
  },
  getAnchorTip(state, getters) {
    return getters.getAnchor?.tip;
  },
  getCorrectResults(state, getters) {
    return getters.getCurrentStudentExercise?.detailed_results?.filter(
      (f) => f.num_errors === 0
    );
  },
  getIncorrectResults(state, getters) {
    return getters.getCurrentStudentExercise?.detailed_results?.filter(
      (f) => f.num_errors > 0
    );
  },
  isHintVisible(state) {
    return !!state.hintVisible;
  },
  getWordIndex(state, getters, rootState, rootGetters) {
    if (rootGetters["router/isTest"]) {
      return getters.getTest?.current_word;
    }

    return getters.getCurrentStudentExercise?.current_word;
  },
  getTestProgress(state, getters) {
    let currentProgress = null;
    let totalProgress = 0;
    let test = getters.getTest;
    let currentStep = test.current_step;

    Object.entries(test.words).find((entry) => {
      const step = entry[0];
      const words = entry[1];

      words.forEach((word) => {
        if (word === getters.getWord && parseInt(step) === currentStep) {
          currentProgress = totalProgress;
        }

        totalProgress++;
      });
    });

    return { current: currentProgress, total: totalProgress };
  },
  getWord(state, getters, rootState, rootGetters) {
    if (rootGetters["router/isTest"]) {
      const test = getters.getTest;
      return test?.words[test.current_step][test.current_word];
    }

    return getters.getWordData?.word;
  },
  getLastAnswer(state, getters) {
    return getters.getCurrentStudentExercise?.detailed_results?.[
      getters.getCurrentStudentExercise.current_word
    ]?.last_answer?.word;
  },
  getSentence(state, getters) {
    return getters.getWordData?.audio_sentence;
  },
  getFilteredSentence(state, getters) {
    const prefilledSentence = getters.getExerciseData?.word?.sentence;
    if (prefilledSentence) return prefilledSentence;
    return "";
    // const regexp = new RegExp(`\\bschuif\\b`, "gm");
    // return getters.getWordData?.audio_sentence?.replace(regexp, "...");
  },
  getAnswer(state, getters, rootState, rootGetters) {
    if (rootGetters["template/isTemplate"]("KiesKlank")) {
      return getters.getExpectedInput.sound_group;
    }

    return getters.getWordData?.word;
  },
  getWordData(state, getters) {
    return getters.getCurrentStudentExercise?.exercise_data?.word;
  },
  getWords(state, getters, rootState, rootGetters) {
    if (rootGetters["router/isTest"] && getters.getTest) {
      const allWords = [];
      Object.entries(getters.getTest?.words).find((entry) => {
        const words = entry[1];

        words.forEach((word) => {
          allWords.push(word);
        });
      });

      return allWords;
    }

    return getters.getCurrentStudentExercise?.words ?? [];
  },
  getProgress(state, getters) {
    return {
      current: getters.getWordIndex,
      total: getters.getWords?.length,
      step: state.progress.step,
      word: getters.getWord,
      errors: state.progress.errors,
      cheats_used: state.progress.cheats_used,
      hints_used: state.progress.hints_used,
    };
  },
  getProgressStep(state) {
    return state.progress.step;
  },
  getProgressWord(state, getters) {
    return getters.getProgress.word;
  },
  getErrors(state) {
    return state.progress?.errors;
  },
  getTestStep(state, getters) {
    return getters.getTest?.current_step;
  },
  getTitle(state, getters, rootState, rootGetters) {
    if (rootGetters["router/isTest"]) {
      return getters.getTest?.label ?? "Toets";
    }

    const exercise = getters.getExercise;
    if (rootGetters["router/isExercise"] && exercise) {
      return exercise.name && exercise.name !== ""
        ? exercise.name
        : exercise.exercise_type.name;
    }

    return "";
  },
  isLastWord(state, getters) {
    return getters.getWord === getters.getWords.length - 1;
  },
  getColors() {
    return [
      { id: 1, c: "#E4032C", name: "red" },
      { id: 2, c: "#43AB34", name: "green" },
      { id: 3, c: "#FFDA00", name: "yellow" },
      { id: 4, c: "#EA68CE", name: "pink" },
      { id: 5, c: "#000000", name: "black" },
      { id: 6, c: "#823F91", name: "purple" },
      { id: 7, c: "#ED6B06", name: "orange" },
      { id: 8, c: "#7E4F39", name: "brown" },
      { id: 9, c: "#0C56A8", name: "darkblue" },
      { id: 10, c: "#ffffff", name: "white" },
      { id: 11, c: "#7CCEF3", name: "lightblue" },
    ];
  },
  getRepeatables: (state) => state.repeatables,
  isCheatUsed() {
    return state.progress.cheats_used;
  },
  isHintUsed() {
    return state.progress.hints_used;
  },
  isWordVisible() {
    return state.wordVisible;
  },
  hasProgressBar(state, getters, rootState, rootGetters) {
    return (
      rootGetters["router/isTest"] || getters.getExercise?.show_progress_bar
    );
  },
  getDifficultyIcon: () => (difficulty) => {
    return Vue.prototype.$helper.getPng(
      "difficulty-" + difficulty + ".png",
      "exercises/"
    );
  },
  getDocuments: (state, getters) => {
    return getters.getCurrentStudentExercise.documents;
  },
  getTwoWordStoryWords: (state) => {
    return state.story.chosenWords;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
