import {Profile} from "@/models/Profile";
import {Student} from "@/models/Student";
import Vue from "vue";
import router from "@/router";
import Moment from "moment";
import Cookies from "js-cookie";

const defaultState = () => ({
    profile: null,
    levels: [],
    badges: [],
    progress: [],
    notes: [],
    books: [],
    books_read: [],
    difficulty: 2,
});

/**
 * @namespace
 * @property {Reward[]} state.badges
 * @property {Note[]} state.notes
 * @property {UserReport[]} state.books
 * @property {UserReport[]} state.books_read
 * */
const state = defaultState;

const mutations = {
    LOGOUT() {
        Cookies.remove("user");
    },
    SET_USER(state, user) {
        Cookies.set("user", user);
    },
    UPDATE_STUDENT_PROFILE(state, studentProfile) {
        state.profile.student = new Student(studentProfile);
    },
    SET_PROFILE(state, profile) {
        state.profile = new Profile(profile);
    },
    SET_LEVELS(state, payload) {
        state.levels = payload;
    },
    SET_BADGES(state, badges) {
        state.badges = badges;
    },
    SET_DIFFICULTY(state, difficulty) {
        state.difficulty = difficulty;
    },
    SET_DEFAULT_STATE(state) {
        Object.assign(state, defaultState());
    },
    SET_PROGRESS(state, payload) {
        state.progress = payload;
    },
    SET_NOTES(state, payload) {
        state.notes = payload;
    },
    SET_BOOKS(state, payload) {
        state.books = payload;
    },
    SET_BOOKS_READ(state, payload) {
        state.books_read = payload;
    },
};

// noinspection JSUnusedGlobalSymbols
const actions = {
    login({dispatch, commit}, {username, password}) {
        return Vue.prototype.$api
            .login(username, password)
            .then(async ({data}) => {
                const {access_token, refresh_token} = data;
                await dispatch(
                    "auth/setTokens",
                    {access_token, refresh_token},
                    {root: true}
                );
                await dispatch("setUser", username);
                Vue.prototype.$api.log("login");

                if (router.currentRoute.name !== "world_overview") {
                    await router.push("/");
                }

                // return dispatch("fetchProfile")
                //   .then(() => {
                //     const notification = new Notification({
                //       type: "success",
                //       message: `Ingelogd als ${username}`,
                //     });
                //
                //     dispatch("notification/add", notification, { root: true });
                //
                //     return username;
                //   })
                //   .catch(() => {
                //     dispatch(
                //       "notification/add",
                //       {
                //         type: "error",
                //         message:
                //           "Het account is niet juist gekoppeld. Neem contact op.",
                //       },
                //       { root: true }
                //     );
                //     dispatch("user/logout", null, { root: true });
                //   });
            })
            .catch((error) => {
                let message = (error.response.data.message === "User has no active subscription") ? "Je kunt niet inloggen omdat je abonnement verlopen is" : "Er was een fout tijdens het inloggen";
                let notification = {
                    type: "error",
                    message: message,
                };
                dispatch("notification/add", notification, {root: true});
                commit("LOGOUT");
                throw error;
            });
    },
    async impersonate({dispatch, commit}, token) {
        return await Vue.prototype.$api.impersonate(token).then(({data}) => {
            const {access_token, refresh_token} = data;
            dispatch(
                "auth/setTokens",
                {access_token, refresh_token},
                {root: true}
            );

            commit("SET_USER", "impersonating");
        });
    },
    logout({commit, dispatch}) {
        commit("SET_DEFAULT_STATE");
        commit("LOGOUT");
        dispatch("pusher/unsubscribe", null, {root: true});
        dispatch("auth/clearTokens", null, {root: true});

        if (router.currentRoute.name !== "login") {
            router.push("/login");
        }
    },
    fetchProfile({commit, dispatch}) {
        return Vue.prototype.$api.endpoints.me.get("", false).then((data) => {
            if (!data.student) {
                throw "noStudentException";
            } else {
                commit("SET_PROFILE", data);
                dispatch("setUser", data?.email);
                if (!data.has_active_subscription) {
                    if (router.history.pending.name !== "bestellen") {
                        router.push({ name: 'bestellen' })
                    }
                }
            }
        });
    },
    setUser({commit}, username) {
        commit("SET_USER", username);
    },
    fetchProgress({commit}) {
        return Vue.prototype.$api.endpoints.progress
            .get("", false)
            .then((data) => commit("SET_PROGRESS", data));
    },
    fetchLevels({commit}) {
        return Vue.prototype.$api.endpoints.level.get("", false).then((data) => {
            commit(
                "SET_LEVELS",
                data.reduce((acc, level) => {
                    acc.push({value: level.id, text: level.name});
                    return acc;
                }, [])
            );
        });
    },
    fetchNotes({commit}) {
        return Vue.prototype.$api.endpoints.note.get().then((data) => {
            commit("SET_NOTES", data);
        });
    },
    fetchBooks({commit}) {
        return Vue.prototype.$api.endpoints.book.get().then((data) => {
            commit("SET_BOOKS", data);
        });
    },
    fetchBooksRead({commit}) {
        return Vue.prototype.$api.endpoints["book-read"].get().then((data) => {
            commit("SET_BOOKS_READ", data);
        });
    },
    addBookRead({dispatch}, {title, review}) {
        return Vue.prototype.$api.endpoints["book-read"]
            .post({
                title,
                review,
            })
            .then(() => dispatch("fetchBooksRead"));
    },
    deleteBookRead({dispatch}, id) {
        return Vue.prototype.$api.endpoints["book-read"]
            .delete(id)
            .then(() => dispatch("fetchBooksRead"));
    },
    addNote({dispatch}, {title, text, date = null}) {
        return Vue.prototype.$api
            .post("/api/v1/note", {
                date: date ?? Math.round(Date.now() / 1000),
                title,
                text,
            })
            .then(() => dispatch("fetchNotes"));
    },
    updateNote({dispatch}, {id, title, text}) {
        return Vue.prototype.$api.endpoints.note
            .put(id, {
                title,
                text,
            })
            .then(() => dispatch("fetchNotes"));
    },
    deleteNote({dispatch}, id) {
        return Vue.prototype.$api.endpoints.note
            .delete(id)
            .then(() => dispatch("fetchNotes"));
    },
    updateStudentProfile({commit}, studentProfile) {
        return Vue.prototype.$api
            .post("/api/v1/user/update", studentProfile.getProperties())
            .then(({data}) => {
                commit("UPDATE_STUDENT_PROFILE", studentProfile);
                commit("SET_USER", data);
                return data;
            });
    },
    fetchBadges({commit}) {
        return Vue.prototype.$api.endpoints.reward.get().then((data) => {
            commit("SET_BADGES", data);
        });
    },
    buyBadge({dispatch}, id) {
        return Vue.prototype.$api.endpoints.buyReward.post({id}).then(() => {
            return dispatch("startInitialization", null, {root: true}).then(
                async () => {
                    await dispatch("fetchBadges");
                    await dispatch("fetchProfile");
                    return dispatch("stopInitialization", null, {root: true});
                }
            );
        });
    },
    setDifficulty: ({commit, state}, difficulty) => {
        commit("SET_DIFFICULTY", difficulty);
        return state.difficulty;
    },
};

// noinspection JSUnusedGlobalSymbols
const getters = {
    isLoggedIn: (state, getters, rootState, rootGetters) => {
        return (
            rootGetters["user/getProfile"] &&
            rootGetters["user/getProfile"].student &&
            rootGetters["auth/getAccessToken"]
        );
    },
    isGuest: (state, getters) => {
        return !getters.isLoggedIn;
    },
    getProfile: (state) => {
        return state.profile ? new Profile(state.profile) : null;
    },
    getScreenName: (state, getters) => {
        return getters.getProfile?.student?.screen_name;
    },
    getBuddyId: (state, getters) => {
        return getters.getProfile?.student?.buddy_id ?? 1;
    },
    createBuddyImage: (state, getters) => (id) => {
        if (getters.getBuddyId) {
            return `https://cdn.spellit.nl/buddies/${getters.getBuddyId}/${id}.png`;
        }

        return null;
    },
    /**
     * @return {Student|null}
     */
    getStudent: (state) => {
        return state.profile && state.profile.student
            ? new Student(state.profile.student)
            : null;
    },
    getProgress: (state) => {
        return state.progress;
    },
    getBadges: (state) => {
        return state.badges;
    },
    /** @return Reward[] */
    getMyBadges: (state) => {
        return [...state.badges.filter((e) => e.student_has_badge)];
    },
    getUnboughtBadges: (state) => {
        return state.badges.filter((e) => !e.student_has_badge);
    },
    getLevels: (state) => {
        return state.levels;
    },
    isIntroductionCompleted: (state) => {
        return (
            state.profile &&
            state.profile.student &&
            state.profile.student.introduction_completed
        );
    },
    getDateOfBirthMoment: (state, getters) => {
        if (!getters.getStudent?.date_of_birth) return null;
        return Moment(getters.getStudent.date_of_birth * 1000);
    },
    getNotes: (state) => {
        return state.notes;
    },
    /** @return {UserReport[]} */
    getBooks: (state) => {
        return state.books;
    },
    /** @return {UserReport[]} */
    getBooksRead: (state) => {
        return state.books_read;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
