// noinspection JSUnresolvedVariable

import Vue from "vue";
import Router from "vue-router";

import TreeHouse from "../views/TreeHouse.vue";
import Shop from "../views/Shop.vue";
import Subscription from "../views/Subscription.vue";
import EndCave from "../views/EndCave.vue";

import WorldOverview from "../views/WorldOverview.vue";

import Logboek from "../views/Logboek.vue";
import MyBadges from "../views/MyBadges.vue";
import Boekenplank from "../views/Boekenplank.vue";

import Login from "@/views/Login.vue";
import Welcome from "@/components/Profile/Welcome";

import store from "@/store/index";
import Profile from "@/views/Profile";
import LessonPage from "@/views/LessonPage";
import Exercise from "@/views/Exercise";
import Cookies from "js-cookie";


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "world_overview",
      component: WorldOverview,
      meta: {
        auth: true,
        fetch: {
          actions: [{ action: "world/fetchSteps" }],
        },
      },
    },
    {
      path: "/wereld/:world_id",
      name: "world",
      component: WorldOverview,
      meta: {
        auth: true,
        fetch: {
          actions: [
            {
              action: "world/fetchSteps",
              allow: (action) =>
                !(
                  action === "world/fetchSteps" &&
                  window.isUAMobile &&
                  store.state.world.steps.length > 0
                ),
            },
          ],
        },
      },
    },
    {
      path: "/boomhut",
      name: "treehouse",
      component: TreeHouse,
      meta: {
        auth: true,
        fetch: {
          actions: [
            { action: "user/fetchProfile" },
            { action: "world/fetchSteps" },
            // { action: "user/fetchProgress" }, //Moved to ProgressModal.vue mounted()
            { action: "exercise/fetchRepeatables" },
            { action: "activity/logTreehouse" },
          ],
        },
      },
    },
    {
      path: "/profiel",
      name: "profile",
      component: Profile,
      meta: {
        auth: true,
        jungle: { title: "Mijn profielpagina" },
        fetch: {
          actions: [
            { action: "user/fetchLevels" },
            { action: "user/fetchProfile" },
          ],
        },
      },
    },
    {
      path: "/shop",
      name: "shop",
      component: Shop,
      meta: { auth: true, jungle: { title: "Shop" } },
    },
    {
      path: "/bestellen",
      name: "bestellen",
      component: Subscription,
      meta2: { auth: true, jungle: { title: "Bestellen" } },
      meta: { auth: true },
    },
    {
      path: "/eindgrot",
      name: "eindgrot",
      component: EndCave,
      meta: { auth: true },
    },
    {
      path: "/fase/:id/stap/:stap_id",
      name: "lesson_page",
      component: LessonPage,
      meta: {
        auth: true,
        jungle: {
          title: () => store.getters["lessonPage/getStep"]?.label,
          backlink: () => {
            if (
              store.getters["lessonPage/getStep"] &&
              store.getters["lessonPage/getStep"].phase_id === null
            ) {
              return { name: "treehouse" };
            }
            return {
              name: "world",
              params: { world_id: store.getters["world/getActiveWorld"] },
            };
          },
        },
        fetch: {
          actions: [{ action: "lessonPage/fetchStep", param: "stap_id" }],
        },
      },
    },
    // {
    //   path: "/herhaaloefeningen/:herhaal_id",
    //   name: "lespagina-herhaaloefeningen",
    //   component: LespaginaHerhaaloefeningen,
    //   meta: { auth: true },
    // },
    {
      path: "/fase/:id/stap/:stap_id/:exercise_id",
      name: "exercise",
      component: Exercise,
      meta: {
        jungle: {
          title: () => store.getters["exercise/getTitle"],
          backlink: () => ({ name: "lesson_page" }),
        },
        auth: true,
        fetch: {
          actions: [
            { action: "exercise/fetchExercise", param: "exercise_id" },
            { action: "lessonPage/fetchStep", param: "stap_id" },
          ],
        },
      },
    },
    {
      path: "/test/:stap_id/:test_id",
      name: "test",
      component: Exercise,
      meta: {
        jungle: {
          title: () => store.getters["exercise/getTitle"],
          backlink: () => ({
            name: "world",
            params: { world_id: store.getters["world/getActiveWorld"] },
          }),
        },
        auth: true,
        fetch: {
          actions: [{ action: "exercise/fetchTest", param: "test_id" }],
        },
      },
    },
    {
      path: "/welcome",
      name: "welcome",
      component: Welcome,
      meta: {
        auth: true,
        jungle: {
          title: () => "Welkom!",
          backlink: () => null,
        },
      },
    },
    {
      path: "/logboek",
      name: "logboek",
      component: Logboek,
      meta: {
        fetch: { actions: [{ action: "user/fetchNotes" }] },
        auth: true,
        jungle: {
          title: "Logboek",
        },
      },
    },
    {
      path: "/mijn-badges",
      name: "mijn badges",
      component: MyBadges,
      meta: {
        auth: true,
        jungle: {
          title: "Behaalde badges",
          backlink: { path: "/boomhut" },
        },
      },
    },
    {
      path: "/boekenplank",
      name: "boekenplank",
      component: Boekenplank,
      meta: {
        fetch: {
          actions: [
            { action: "user/fetchBooks" },
            { action: "user/fetchBooksRead" },
          ],
        },
        auth: true,
        // jungle: {
        //   title: "Boekenplank",
        //   backlink: { path: "/boomhut" },
        //   background: "boekenplank.png"
        // },
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/logout",
      name: "logout",
      component: Login,
    },
    {
      path: "/login-by-token",
      name: "loginByToken",
      component: Login,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("startInitialization");
  store.commit("router/SET_ROUTE", to);
  await store.dispatch("audio/clearAudio");

  if (to.name !== "oefening" && to.name !== "test") {
    await store.dispatch("exercise/clearProgress", to);
  }

  const loggedIn = Cookies.get("user");

  if (loggedIn && to.name === "login") {
    next({ name: "world_overview" });
    return;
  }

  if (to.matched.some((record) => record.meta.auth)) {
    if (!loggedIn) {
      next({ name: "login" });
      return;
    }

    await store.dispatch("user/fetchProfile");
    await store.dispatch("user/fetchBadges");

    if (to.meta.fetch) {
      if (to.meta.fetch.action) {
        throw Error("DEPRECATED");
        // const param = to.meta.fetch.param;
        // if (!to.meta.fetch.allow || to.meta.fetch.allow(to.meta.fetch.action)) {
        //   // if allowed
        //   await store.dispatch(to.meta.fetch.action, to.params[param]);
        // }
        //
        // next();
        // return await store.dispatch("stopInitialization");
      } else if (to.meta.fetch.actions) {
        await Promise.all(
          to.meta.fetch.actions
            .filter((action) => {
              return !action.allow || action.allow(action.action);
            })
            .map((action) => {
              return store.dispatch(
                action.action,
                action?.param ? to.params[action.param] : null
              );
            })
        );

        next();
        return await store.dispatch("stopInitialization");
      }
    }
  }

  next();
  await store.dispatch("stopInitialization");
});

export default router;
